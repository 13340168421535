<template>
  <div
    v-if="complaint"
    class="w-full flex flex-col justify-center items-center gap-10"
  >
    <Wrapper class="max-w-2xl">
      <div class="w-full max-w-xl">
        <h2 class="mb-5 text-blue-dark text-left text-2xl md:text-3xl">
          <b>Detalle de una queja</b>
        </h2>
        <ComplaintDetailHeaderCard
          :radicado="$route.params.complaint_id"
          detail="true"
          :status="complaint.estado_cod"
        />
      </div>
      <div class="w-full">
        <form @submit.prevent="sendForm" class="max-w-xl py-6">
          <DragDrop
            id="attachment"
            @files-handler="setFiles"
            :fileList="files"
            label="Nuevos anexos de la queja"
            buttonLabel="ADJUNTAR NUEVOS SOPORTES"
          />
          <p class="my-2 text-xs text-center text-gray-500">
            *Peso máximo de soportes y anexos {{allowedFileSize > 1 ? Math.floor(allowedFileSize): allowedFileSize.toFixed(2)}} MB
          </p>
          <p class="my-3 text-sm text-red-500">{{ feedback }}</p>
          <div class="w-full flex flex-col gap-4">
            <a-button
              :disabled="!files.length"
              type="primary"
              html-type="submit"
              shape="round"
              :block="true"
              :loading="loading"
            >
              SUBIR ARCHIVOS
            </a-button>
            <a-button
              type="danger"
              shape="round"
              :block="true"
              ghost=""
              @click.native="$router.go(-1)"
            >
              CANCELAR
            </a-button>
          </div>
        </form>
      </div>
    </Wrapper>
    <!-- 
    <div class="w-11/12 md:w-full mt-5 md:mt-0 max-w-2xl">
      <AccordionList :items="accordionList"/>
    </div>-->
  </div>
</template>

<script>
import checkFileSize from "@/utils/checkFileSize";
import ComplaintDetailHeaderCard from "@/components/Customer/Complaint/ComplaintDetailHeaderCard";
export default {
  components: {
    ComplaintDetailHeaderCard,
  },
  created() {},

  data() {
    return {
      accordionList: [
        {
          title: "¿Cómo adjuntar más archivos?",
          body: "En caso que usted quede inconforme con la respuesta dada por la , puede replicar. ",
        },
      ],
      form: {
        tipo: 1,
        argumento: "",
      },
      files: [],
      reason: "",
      feedback: "",
      loading: false,
    };
  },
  methods: {
    async sendForm() {
      let form = {
        codigo_queja: this.complaint.codigo_queja,
        tipo: 3,
      };
      this.loading = true;
      let { data, error } = await this.$api.setAnexos(form, this.files);
      this.loading = false;
      if (error) {
        this.$notification.error({
          message: "Error",
          description: "Ocurrio un error",
        });
      }
      if (data) {
        this.$notification.success({
          message: "Notificación",
          description: "Se subieron correctamente los archivos",
        });
        this.$router.go(-1);
      }
    },

    setFiles(files) {
      if (checkFileSize(files, this.allowedFileSize)) {
        this.feedback =
          "Con el archivo que intenta cargar, se supera el peso máximo permitido de los anexos. Valide la información.";
      } else{
        this.files = files;
        this.feedback = "";
      }
    },
   
  },

  computed: {
    complaint: function () {
      return {
        complaint_id: this.$route.params.complaint_id,
        codigo_queja: this.$route.params.complaint_id,
        estado_cod: this.$route.params.estado_cod,
      };
    },

    allowedFileSize: function(){
      let total = 90 - this.$route.params?.annexesSize;
      return total >= 30 ? 30 : total
    }
    
  },
};
</script>

<style></style>
